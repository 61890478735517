$black: #0d1324;
$white: #ffffff;
$white10: #161e35;
$white20: #273252;
$white30: #3b4666;
$white40: rgb(117, 119, 139);
$white60: rgb(136, 139, 170);
$white80: rgb(197, 199, 223);
$white90: #f2f4fd;
$white-background: #f2f4fd;

$greytext: #a9a9c2;

$btest: #1e2431;
$green: #42b391;
$green-light: #61d2aa;
$green-dark: #339b7b;
// $white: #eff2fc;
$white-light: #ffffff;
$white-dark: #d6dae8;

$white-grey: #bdc4cc;
$grey: #86919d;
$dark-grey: #2c3040;

$red: #e33939;

$yellow-light: #fdf5e3;
$yellow-dark: #f5b92a;
$blue-light: #e2f2ff;
$blue-dark: #0795ff;
$pink-light: #fbe5f6;
$pink-dark: #eb88d6;

$secondary-red-light: #ffd3d3;
$secondary-red-dark: #833030;
$secondary-lavender-light: #d5defd;
$secondary-lavender-dark: #385789;
$secondary-yellow-light: #fff0c7;
$secondary-yellow-dark: #625c2c;
$secondary-purple-light: #f3c6fc;
$secondary-purple-dark: #722b83;
$secondary-green-light: #beffd7;
$secondary-green-dark: #196047;
// $black: #07100EDE;
// $btest : #1E2431;
// $green : #26A07B;
// $green-light: #61D2AA;
// $white: #EFF2FC;
// $white-light: #FFFFFF;
// $white-dark: #d6dae8;

// $white-grey: #BDC4CC;
// $grey: #86919D;
// $dark-grey: #E6E6E6;
