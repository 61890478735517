// @import 'Styles/colors';

@import '../../../Styles/colors';
@import '../../../Styles/mixins';
// @import 'Styles/mixins';

.cpn-info-bar {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 1.1em;
    font-weight: 300;
    margin: 0;
    color: $grey;
  }

  .name {
    margin: 15px 0 2px;
  }

  .cnt-info {
    display: flex;
    color: $grey;

    .cte-info {
      margin-right: 30px;
      display: flex;

      .icon {
        margin-right: 7px;

        .mat-icon {
          font-size: 1.7em;
        }
      }

      .info {
        text-transform: uppercase;
        font-size: 1em;
      }
    }
  }

  .logo {
    max-width: 100px;
  }
}

.form-menu {
  max-width: 300px;
  height: 100%;
  width: 100%;
  @include flexbox;
  @include flex-direction(column);
  min-height: 100vh;
  background: $black;
  overflow: auto;

  .header {
    padding: 20px;
    @include flexbox;
    @include justify-content(top);
    @include align-items(left);
    height: 40px;
    margin-bottom: 0px;

    .ctn-logo {
      max-width: 75px;

      .logo {
        width: 100%;
      }
    }
  }
  .body {
    padding: 20px 20px 0;

    .ctn-title {
      font-weight: 300;
      margin: 0;
      color: $white40;
      @include font-size(16px);
      .title {
        margin: 0;
        text-align: left;
        width: 100%;
      }
    }

    .ctn-name {
      @include flexbox;
      @include flex-direction(row);
      @include justify-content(space-between);

      .change-link-datas{
        color: white;
        width: 20%;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
      }

      .name-text{
        @include flexbox;
        @include flex-direction(row);
  
        .name {
          font-weight: 700;
          color: $white;
          @include font-size(18px);
          margin: 0;
          text-align: left;
          word-break: break-word;
        }
      }
    }
    .ctn-info {
      @include flexbox;
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(left);
      margin: 10px 0;

      .cte-info {
        @include flexbox;
        margin: 2px 0;
        .icon {
          margin-right: 10px;

          .mat-icon {
            color: $white40;
            font-size: 1.4em;
          }
        }
        .info {
          font-weight: 500;
          text-transform: uppercase;
          @include font-size(15px);
          color: $white40;
        }
      }
    }
  }

  .bottom {
    .ctn-help {
      padding: 15px 20px 20px;
      background-color: $dark-grey;
      cursor: pointer;

      .cte-title {
        @include flexbox;
        @include flex-direction(row);
        @include align-items(center);
        .icon {
          margin-right: 10px;

          .mat-icon {
            color: $white;
            font-size: 1.7em;
            margin-top: 5px;
          }
        }
        .head {
          .title {
            font-weight: 700;
            color: $white;
            @include font-size(16px);
            margin: 0;
          }
        }
      }
      &.responsive-true {
        .cte-contact{
          height: 0;
          padding: 0;
          margin: 0;
          overflow: hidden;
        }
      }
    }
  }
  .ctn-menu-component {
    padding: 5px 20px;
    @include flexbox;
    @include flex-direction(column);
    @include align-items(left);

    &.end {
      height: 100%;
      padding: 5px 20px 20px;
    }
    .sub-header{
      @include flexbox;
      @include flex-direction(row);
      @include align-items(center);
      @include justify-content(space-between);
      cursor: pointer;

      .sct-title {
        @include font-size(15px);
        color: $white;
        font-weight: 500;
        margin: 7px 0 7px;
      }
      .icon{
        color: white;
      }
    }


    .end-btn-ctn {
      .end-btn {
        width: 100%;
        background: $green;
        color: white;
        font-weight: 700;
        padding: 10px 16px;

        .MuiSvgIcon-root{
          margin-right: 5px;
        }

        &.active-true.form-edit-true {
          @include keyframes(fade) {
            0% {
              transform: scale(1);
            }
            15% {
              transform: scale(0.98);
              box-shadow: 0 0 0 0 rgba(162, 245, 220, 0.5);
            }
            30% {
              transform: scale(1);
            }
            50% {
            }

            70% {
              transform: scale(1);
              box-shadow: 0 0 0 10px rgba(162, 245, 220, 0);
            }

            100% {
              transform: scale(1);
              box-shadow: 0 0 0 0 rgba(162, 245, 220, 0);
            }
          }
          @include animate(fade, 1.9s, ease, infinite);
        }
        &:hover {
          background: $green-light;
        }
        &.form-edit-false {
          background: $green-light;
          &:hover {
            background: $green;
          }
        }

        &.Mui-disabled {
          background: $white30;
          color: $white60;
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
        }
      }
      &:hover {
        cursor: pointer;
      }
    }

    .link {
      color: $green;
      @include flexbox;
      @include flex-direction(row);
      @include align-items(center);
      margin: 0 0 5px;
      cursor: pointer;
      transition: all 0.2s;

      .link-icon {
        margin-right: 8px;
        margin-bottom: 4px;
      }

      .link-text {
        margin: 0;
        text-decoration: underline;
      }
    }
    &.responsive-true {
      .link{
        height: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;
      }
    }
  }
  .cte-contact {
    padding-top: 5px;
    padding-left: 5px;
    transition : all 0.1s;
    .name {
      font-weight: 300;
      color: $white;
      opacity: 0.4;
      @include font-size(15px);
      margin: 0 0 2px;
      line-height: 1.3em;
    }
    .role {
      font-weight: 300;
      color: $white;
      opacity: 0.4;
      @include font-size(15px);
      margin: -3px 0 7px;
      line-height: 1.3em;
    }
    .ctn-info {
      @include flexbox;
      @include flex-direction(row);
      @include align-items(center);
      .mat-icon {
        margin-right: 5px;
        color: $white;
        font-size: 1.1em;
        margin-top: -1px;
      }
      .email {
        text-decoration-color: $white;
      }
      .info {
        font-weight: 500;
        color: $white;
        @include font-size(14px);
        margin: 0px 0 0px;
      }
    }
  }
}
.cte-tabs {
  width: 100%;
  transition: 0.2s all;
  .tab-value{
    @include flexbox;
    @include flex-direction(row);
    @include align-items(center);
    .tab-number{
      margin-left: 10px;
      padding: 3px 8px;
      border-radius: 4px;
      background: rgba(51, 155, 123, 0.5);
      font-size: 10px;
      font-weight: 700
    }
  }
  .MuiTab-textColorInherit.Mui-selected {
    background: $green;
    .tab-number{
      background: rgba(51, 155, 123, 1);
    }
  }
}
.cpn-tabs {
  @include flexbox;
  @include flex-direction(row);
  @include align-items(center);
  @include justify-content(space-between);

  .menu-open {
    padding: 0px 20px 0;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
  }
  .displayMenu{
    display: none;
  }
  .ctn-tabs {
    width: 100%;
    @include flexbox;
    @include flex-direction(column);
    @include align-items(flex-end);
    @include justify-content(space-between);
    .menu-bar-top {
      @include flexbox;
      @include flex-direction(row);
      @include align-items(center);
      margin-right: 20px;
    }
    .menu-bar {
      @include flexbox;
      @include flex-direction(row);
      @include align-items(center);
      @include justify-content(space-between);
      width: 100%;

      .PrivateTabIndicator-colorSecondary-8 {
        background-color: $green;
      }
      .change-tab {
        @include flexbox;
        @include flex-direction(row);
        @include align-items(center);
        @include justify-content(space-between);

        .cte-change {
          @include flexbox;
          @include flex-direction(row);
          @include align-items(center);
          margin: 10px;
          cursor: pointer;

          .MuiSvgIcon-root {
            margin: 0;
            margin-bottom: 1px;
            height: 25px;
            width: 25px;
            color: $white;
          }
          .change {
            margin: 0;
            font-size: 13px;
            text-transform: uppercase;
          }
        }
      }
      .save-message {
        @include flexbox;
        @include flex-direction(row);
        @include align-items(center);

        height: 30px;
      }
      .save-message-title {
        margin: 0;
        font-weight: 500;
        color: $green;
        @include font-size(13px);
      }
      .save-message-icon {
        color: $green;
        margin: 0 15px 0 10px;
        @include font-size(20px);
      }
      .MuiCircularProgress-colorPrimary {
        height: 20px !important;
        width: 20px !important;
        margin: 0 15px 0 10px;
        color: $green;
      }
    }
  }
}

@media (max-width: 1400px) {
  .ctn-tabs {
    .MuiTab-root {
      min-width: 100px;
      letter-spacing: 0;
    }
  }
}

@media (max-width: 900px) {
  .cpn-tabs{
    
    .menu-open{
      position: absolute;
      bottom: 11px;
      left: 0px;
    }
    .displayMenu{
      cursor: pointer;
      display: block;
      position: absolute;
      bottom: 2px;
      padding: 0px 20px 0;
      right: 0;
    }
    .ctn-tabs{
      .menu-bar{
        @include flexbox;
        @include flex-direction(column);

        .MuiTabs-flexContainer{
          @include flex-direction(column);

          .MuiButtonBase-root{
            max-width: 100%;
          }
        }
        .save-message{
          display: none;
        }
      }
    }
  }
  &.tabs-open-true{

    .cte-tabs{
      height: 0;
      padding: 0;
      margin: 0;
      overflow: hidden;
    }
  }
}

.cpn-modal-change-identity{
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);

  .modal-change-infos{
    background: white;
    max-width: 500px;
    min-width: 400px;
    padding: 40px;
    border-radius: 4px;

    .title{
      margin: 0;
      @include font-size(23px);
      color: $black;
      text-align: center;
    }
    .modal-form{
      margin: 25px 0;

      .input-line{
        margin: 20px 0;

        .label{
          font-weight: 700;
          margin-bottom: 10px;
          @include font-size(14px);
          color: $white40
        }
      }
    }
    .ctn-button{
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);

      .button{
        margin: 0 10px;
      }
      .button-pass{
        color:$white40;
      }

      .button-change{
        background-color:$green-light;
        &:hover{
          background: $green;
        }
        &.Mui-disabled{
          background: $white80;
        }
      }
    }
  }
}

