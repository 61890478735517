@import '../../Styles/colors';
@import '../../Styles/mixins';

html,
body,
#root,
.main-layout {
  height: 100%;
}
.admin-login {
  background-color: $white-background;
  height: 100%;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);

  .ctn-form {
    padding: 70px 50px;
    margin: 30px;
    background: $white;
    border-radius: 4px;
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    .header {
      margin: 35px 0;
      text-align: center;
      .title {
        @include font-size(20px);
        margin: 0;
      }
      .subtitle {
        color: $white80;
        font-weight: 300;
        @include font-size(15px);
        margin: 0;
        margin-top: -2px;
      }
    }

    .cte-form {
      @include flexbox;
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(center);
      width: 100%;
      max-width: 400px;

      .MuiFormControl-root {
        width: 100%;
        margin: 10px;
      }
      .MuiButton-root {
        width: 100%;
        margin: 10px;
        padding: 14px;
        background: $green-light;
        box-shadow: none;
        &:hover {
          background: $green;
        }
      }
    }
  }
}
