@import '../../Styles/colors';
// @import 'Styles/colors';

.home {
    background: $btest;
    min-height: 100vh;
    min-width: 100vh;
    margin: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align:center;

    .MuiInputBase-input.MuiOutlinedInput-input{
        color: white!important;
    }
    
    .MuiFormLabel-root {
    
        color:white!important;
    }

    form{
        display: flex;
        justify-content: center;

            .MuiFormControl-root {

                padding-right: 5%;
                color:white;

                .MuiOutlinedInput-notchedOutline {

                    border-color:white;
                }
            }
    }

    .ctn-form{
        max-width: 500px;
        padding: 30px;
        color: $white-light;

        .title{
            color: $green-light;
        }
    }
}

