// @import 'Styles/colors';
@import '../../Styles/colors';
@import '../../Styles/mixins';
// @import 'Styles/mixins';

.admin-interface {
  @include flexbox;
  @include flex-direction(row);
  height: 100vh;

  .admin-menu {
    background: $black;
    padding: 35px;
    min-width: 300px;
    @include flexbox;
    @include justify-content(space-between);
    @include flex-direction(column);

    .header {
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      height: 150px;
      margin-bottom: 50px;

      .ctn-logo {
        max-width: 120px;

        .logo {
          width: 100%;
        }
      }
    }
    .nav {
      margin-left: -5px;
      .ctn-nav {
        margin-top: 15px;
        .nav-head {
          @include flexbox;
          @include justify-content(space-between);
          @include align-items(center);
          padding: 12px 17px;
          border-radius: 4px;
          text-decoration: none;
          transition: 0.2s;

          .nav-head-icon {
            color: $white40;
            margin-right: 15px;
            width: 1.2em;
            height: 1.2em;
            transition: 0.2s;
          }
          .nav-head-title {
            margin-top: 3px;
            @include font-size(20px);
            font-weight: 500;
            color: $white40;
            transition: 0.2s;
            width: 100%;
          }
        }
        .nav-head:hover {
          background: $white10;
          color: $white;
          .nav-head-icon {
            color: $white;
          }
          .nav-head-title {
            color: $white;
          }
        }
        .nav-head.active {
          background: $green;
          .nav-head-icon {
            color: $white;
          }
          .nav-head-title {
            color: $white;
          }
        }
      }
      .ctn-nav.sub-menu {
        margin-top: 8px;
        .cte-sub-menu {
          @include flexbox;
          @include justify-content(space-between);
          @include align-items(center);
          .nav-head {
            padding-right: 0px;
            padding-bottom: 5px;
            background: none;
            width: 100%;
          }
          .arrow {
            color: $white40;
          }
          .nav-head.active {
            background: none;
          }
        }
      }
      .ctn-nav.sub-menu:hover {
        .nav-head-title {
          color: $white;
        }
        .nav-head-icon {
          color: $white;
        }
      }
      .cte-nav {
        @include flexbox;
        margin-top: 10px;
        .border {
          padding: 1px;
          border-radius: 5px;
          margin: 0px 30px;
          background: $white10;
        }
        .links {
          width: 100%;
          .nav-link {
            @include font-size(18px);
            @include flexbox;
            font-weight: 500;
            color: $white40;
            text-decoration: none;
            padding: 8px 18px;
            border-radius: 5px;
            transition: 0.2s;
            margin-bottom: 5px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
          .nav-link:hover {
            background: $green;
            color: $white;
          }
          .nav-link.active {
            background: $green;
            color: $white;
          }
        }
      }
    }

    .ctn-profil {
      @include flexbox;
      @include justify-content(space-between);
      @include align-items(center);
      margin-left: -5px;

      .cte-img {
        margin-right: 15px;
        .picture {
          max-width: 50px;
          border-radius: 50px;
        }
      }
      .cte-id {
        width: 100%;
        .name {
          @include font-size(19px);
          font-weight: 500;
          color: $white;
          margin: 0;
          margin-bottom: -5px;
        }
        .main-perm {
          margin: 0;
          @include font-size(17px);
          font-weight: 500;
          color: $white40;
        }
      }
      .cte-action {
        .btn-action {
          &.MuiIconButton-root {
            color: $white;
          }

          &.btn-action.MuiIconButton-root:hover {
            background-color: $white10;
          }
        }
      }
    }
  }

  // Dashboard
  .admin-dashboard {
    background: $white-background;
    width: 100%;
    overflow-y: scroll;
    height: 100%;

    .ctn-dashboard {
      padding: 50px;

      .sct-header {
        margin-bottom: 50px;

        .header-title {
          font-weight: 800;
          @include font-size(40px);
          margin: 0;
          color: $black;
        }
        .header-subtitle {
          font-weight: 400;
          @include font-size(18px);
          margin: 0;
          margin-top: -10px;
          color: $greytext;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .admin-interface {
    .admin-menu {
      min-width: 180px;
      padding: 15px;

      .nav {
        .nav-head-title {
          font-size: 1rem !important;
        }
        .nav-head {
          padding: 8px 6px !important;
        }
        .cte-sub-menu {
          .nav-head {
            padding: 8px 6px !important;
          }
        }
        .cte-nav {
          .border {
            margin: 0px 10px 0px 15px !important;
          }
          .nav-link {
            font-size: 0.8rem !important;
            padding: 4px 10px !important;
            &:hover {
              padding: 4px 10px !important;
            }
          }
        }
      }
    }
    .ctn-profil {
      .picture {
        max-width: 35px !important;
      }
      .name {
        font-size: 0.9rem !important;
        margin-bottom: 0px !important;
        line-height: 1.1;
      }
      .main-perm {
        font-size: 0.8rem !important;
      }
    }
  }
}

// .admin {
//   background: $white;
//   min-height: 100vh;

//   .menu {
//     background: $btest;
//     color: $white-light;
//     padding: 10px;

//     .menu-ctn {
//       display: flex;
//       align-content: space-between;
//       align-items: center;

//       .col-left {
//         display: flex;
//         width: 100%;

//         .logo {
//           max-width: 100px;
//         }

//         .title {
//           margin-left: 30px;
//           font-size: 1.4em;
//           opacity: 0.4;
//         }
//       }

//       .col-right {
//         display: flex;
//         flex-direction: row-reverse;
//         width: 100%;
//       }
//     }
//   }

//   .cpn-links {
//     background: $white-light;
//     border-radius: 7px;
//     padding: 0px;
//     overflow: hidden;

//     .MuiTableHead-root {
//       background: $green-light;

//       .MuiTableCell-head {
//         color: $white;
//       }
//     }
//   }

//   .dashboard-title {
//     margin: 50px 0 20px;
//     font-size: 1.5em;
//     font-weight: 700;
//   }

//   .cpn-add-link {
//     border-radius: 7px;
//     padding: 30px;
//     color: $black;
//     border: 1px solid rgba(189, 196, 204, 0.7);
//     background: $white-light;

//     .manage-link {
//       .student-ctn {
//         margin-top: 12px;
//       }

//       .ctn-btn {
//         width: 100%;
//         display: flex;
//         flex-direction: row-reverse;

//         .send {
//           margin-top: 30px;
//         }
//       }
//     }
//   }
// }
